/* src/styles.css */

 
body {
  
  margin: 0;
  background: black;
  padding: 0;
}
.App {
 /* overflow-x-hidden   h-[100vh] overflow-auto duration-200 ease-out */
 overflow-x: hidden;
 height: 100vh;
 overflow: auto;
 transition: ease-out;
}

.darkMode{
  /* text-white bg-[#212121] */
  color: white;
  background: #212121;
  
}
.lightMode{
  /* bg-white text-[#303030]  */
  color: #303030;
  background: white;
  
}
body {
  font-family: 'Cairo', sans-serif;
}

.swiper-container {
  transform: translateZ(0);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.swiper-pagination-bullet {
  background-color: red; /* Change this to the color you desire */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animatedBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
 
.highlighted {
  background-color: white;
  top: 50%; /* Adjust the position based on your design */
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  border-radius: 50%;
  z-index: -1; /* Ensure it's above the overlay */
  height: 5rem;
  width: 5rem;
  position: absolute;
  transform-origin: center center;
  visibility: visible !important;
  transition: transform 0.3s ease-in-out; /* Adjust timing as needed */
}
.menubar-style{
  background: transparent;
  border-top: 0;
  z-index: 50;
}

.highlighted-target {
  z-index: 60; /* Ensure it's above the circle */

}

.index-target{
 visibility: visible !important;
}

@keyframes highlighted {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.custom-scroll-container .content::-webkit-scrollbar {
  width: .6rem;
  height: 60%;
}

.custom-scroll-container .content::-webkit-scrollbar-thumb {
  background-color: #06C671;
  border-radius: 6px;
}

.custom-scroll-container .content::-webkit-scrollbar-track {
  background-color: #dddddd52;
}

.splide-container .splide__pagination__page {
  background: #06C671; /* All dots color */
}

.my-custom-scrollbar::-webkit-scrollbar {
  width: .6rem;
  height: 30%;
 
}

/* Track */
.my-custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.my-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #06C671;
  border-radius: 6px;
}

/* Handle on hover */
.my-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #06C671;
  border-radius: 6px;
}

.swipeable-list-item__content {
  display: block !important; /* Set it to 'block' to remove flex */
  /* Add other styles as needed */
}

 